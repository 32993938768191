import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useMemo, useState } from 'react';
import { Content, Paragraph, Space, Tabs } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks, FigmaEmbed } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "tabs"
    }}>{`Tabs`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Tabs } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=326%3A0'} storybook={'/?path=/story/tabs-tabs--playground'} vueStorybook={'/?path=/story/tabs-tabs--playground'} name={'Tabs'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`Tabs – это компонент с вкладками. Табы используются для навигации между контентом, разделенным на группы.`}</p>
    <p>{`Табы передаются в проп `}<inlineCode parentName="p">{`items`}</inlineCode>{`. Проп `}<inlineCode parentName="p">{`items`}</inlineCode>{` – массив с объектами со свойствами `}<inlineCode parentName="p">{`label`}</inlineCode>{`, `}<inlineCode parentName="p">{`name`}</inlineCode>{`.
Табу можно добавить счетчик, передав в объект таба свойство `}<inlineCode parentName="p">{`counter`}</inlineCode>{` с нужным значением.
Таб можно сделать неактивным, передав свойство `}<inlineCode parentName="p">{`disabled`}</inlineCode>{`.`}</p>
    <p>{`Для управления выбранной вкладкой используйте пропы `}<inlineCode parentName="p">{`activeItem`}</inlineCode>{` и `}<inlineCode parentName="p">{`onItemClick`}</inlineCode>{`.`}</p>
    <p>{`У компонента 3 варианта формы (задается пропом `}<inlineCode parentName="p">{`variant`}</inlineCode>{`) и 3 стиля заливки (задается пропом `}<inlineCode parentName="p">{`tabStyle`}</inlineCode>{`). Подробнее `}<a parentName="p" {...{
        "href": "#%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B"
      }}>{`смотрите в примерах`}</a></p>
    <Playground __position={1} __code={'() => {\n  const tabs = useMemo(\n    () => [\n      {\n        name: \'1\',\n        label: \'Tab №1\',\n      },\n      {\n        name: \'2\',\n        label: \'Tab №2\',\n      },\n    ],\n    [],\n  )\n  const [activeTab, setActiveTab] = useState(tabs[0])\n  return (\n    <>\n      <Tabs activeItem={activeTab} onItemClick={setActiveTab} items={tabs} />\n      <Space size=\"small_3x\" horizontal vertical />\n      {\'1\' === activeTab.name && (\n        <Paragraph style={{ margin: 0 }}>\n          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus\n          consequatur distinctio dolorem doloribus est eum ex exercitationem\n          expedita inventore, iste laborum libero magnam odit optio porro\n          praesentium quae quas recusandae sapiente, vitae! Debitis dolorum\n          ducimus eius error est id, illum ipsa ipsam nobis officia suscipit\n          ullam voluptatum! Modi nesciunt recusandae velit veritatis vitae.\n          Accusantium aperiam at aut beatae culpa, et excepturi harum illo\n          nihil pariatur quas recusandae rerum sapiente tempora.\n        </Paragraph>\n      )}\n      {\'2\' === activeTab.name && (\n        <Paragraph style={{ margin: 0 }}>\n          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus\n          asperiores aspernatur delectus deserunt, dicta ducimus eos explicabo\n          maiores numquam quos ratione sint ut veniam voluptatem voluptatum?\n          Assumenda commodi dolore ex, facere fugiat in labore laudantium\n          magni modi nostrum odit perspiciatis porro rem sed similique sit\n          velit, voluptatem. A distinctio dolorem esse explicabo facere\n          facilis fugit incidunt ipsam laboriosam magni neque, nesciunt\n          obcaecati odio quibusdam quisquam quod sit soluta, ut vel!\n        </Paragraph>\n      )}\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useMemo,
      useState,
      Content,
      Paragraph,
      Space,
      Tabs,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const tabs = useMemo(() => [{
          name: '1',
          label: 'Tab №1'
        }, {
          name: '2',
          label: 'Tab №2'
        }], []);
        const [activeTab, setActiveTab] = useState(tabs[0]);
        return <>
        <Tabs activeItem={activeTab} onItemClick={setActiveTab} items={tabs} mdxType="Tabs" />
        <Space size="small_3x" horizontal vertical mdxType="Space" />
        {'1' === activeTab.name && <Paragraph style={{
            margin: 0
          }} mdxType="Paragraph">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus
          consequatur distinctio dolorem doloribus est eum ex exercitationem expedita inventore, iste laborum libero
          magnam odit optio porro praesentium quae quas recusandae sapiente, vitae! Debitis dolorum ducimus eius error
          est id, illum ipsa ipsam nobis officia suscipit ullam voluptatum! Modi nesciunt recusandae velit veritatis
          vitae. Accusantium aperiam at aut beatae culpa, et excepturi harum illo nihil pariatur quas recusandae rerum
          sapiente tempora.</Paragraph>}
        {'2' === activeTab.name && <Paragraph style={{
            margin: 0
          }} mdxType="Paragraph">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus
          asperiores aspernatur delectus deserunt, dicta ducimus eos explicabo maiores numquam quos ratione sint ut
          veniam voluptatem voluptatum? Assumenda commodi dolore ex, facere fugiat in labore laudantium magni modi
          nostrum odit perspiciatis porro rem sed similique sit velit, voluptatem. A distinctio dolorem esse explicabo
          facere facilis fugit incidunt ipsam laboriosam magni neque, nesciunt obcaecati odio quibusdam quisquam quod
          sit soluta, ut vel!</Paragraph>}
      </>;
      }}
    </Playground>
    <h2 {...{
      "id": "использование"
    }}>{`Использование`}</h2>
    <FigmaEmbed node="?node-id=9786:5929" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "варианты-отображения-и-заливки"
    }}>{`Варианты отображения и заливки`}</h3>
    <p>{`У компонента 3 варианта отображения:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`line`}</inlineCode>{` – вариант с линией внизу`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`square`}</inlineCode>{` – вариант с квадратными краями`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`round`}</inlineCode>{` – вариант со скругленными краями`}</p>
      </li>
    </ul>
    <p>{`Передайте в проп `}<inlineCode parentName="p">{`variant`}</inlineCode>{` нужный. По умолчанию используется `}<inlineCode parentName="p">{`line`}</inlineCode>{`.`}</p>
    <p>{`Также у компонента 3 варианта заливки:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`normal`}</inlineCode>{` – стандартный вариант`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`outlined`}</inlineCode>{` – вариант с обводкой`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`filled`}</inlineCode>{` – вариант с залитым фоном`}</p>
      </li>
    </ul>
    <p>{`Вариант заливки передайте в проп `}<inlineCode parentName="p">{`tabStyle`}</inlineCode>{`. По умолчанию используется `}<inlineCode parentName="p">{`normal`}</inlineCode></p>
    <Playground __position={3} __code={'() => {\n  const tabs = useMemo(\n    () => [\n      {\n        name: \'1\',\n        label: \'Tab №1\',\n      },\n      {\n        name: \'2\',\n        label: \'Tab №2\',\n      },\n      {\n        name: \'3\',\n        label: \'Tab №3\',\n      },\n      {\n        name: \'4\',\n        label: \'Tab №4\',\n      },\n      {\n        name: \'5\',\n        label: \'Tab №5\',\n      },\n      {\n        name: \'6\',\n        label: \'Tab №6\',\n      },\n    ],\n    [],\n  )\n  const [activeTab, setActiveTab] = useState(tabs[0])\n  return Object.keys(Tabs.Variant).map(v =>\n    Object.keys(Tabs.TabStyle).map(s => (\n      <>\n        <Content.Heading size={Content.Heading.Size.H4}>\n          {v} {s}\n        </Content.Heading>\n        <Tabs\n          items={tabs}\n          activeItem={activeTab}\n          onItemClick={setActiveTab}\n          variant={v}\n          tabStyle={s}\n        />\n        <Space size={Space.Size.small} vertical />\n      </>\n    )),\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useMemo,
      useState,
      Content,
      Paragraph,
      Space,
      Tabs,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const tabs = useMemo(() => [{
          name: '1',
          label: 'Tab №1'
        }, {
          name: '2',
          label: 'Tab №2'
        }, {
          name: '3',
          label: 'Tab №3'
        }, {
          name: '4',
          label: 'Tab №4'
        }, {
          name: '5',
          label: 'Tab №5'
        }, {
          name: '6',
          label: 'Tab №6'
        }], []);
        const [activeTab, setActiveTab] = useState(tabs[0]);
        return Object.keys(Tabs.Variant).map(v => Object.keys(Tabs.TabStyle).map(s => <>
        <Content.Heading size={Content.Heading.Size.H4}>{v} {s}</Content.Heading>
        <Tabs items={tabs} activeItem={activeTab} onItemClick={setActiveTab} variant={v} tabStyle={s} mdxType="Tabs" />
        <Space size={Space.Size.small} vertical mdxType="Space" />
      </>));
      }}
    </Playground>
    <h3 {...{
      "id": "отключенный-tab"
    }}>{`Отключенный Tab`}</h3>
    <p>{`Таб можно сделать неактивным, передав свойство `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` в объект`}</p>
    <Playground __position={4} __code={'() => {\n  const tabs = useMemo(\n    () => [\n      {\n        name: \'1\',\n        label: \'Tab №1\',\n      },\n      {\n        name: \'2\',\n        label: \'Tab №2\',\n        disabled: true,\n      },\n    ],\n    [],\n  )\n  const [activeTab, setActiveTab] = useState(tabs[0])\n  return (\n    <>\n      <Tabs activeItem={activeTab} onItemClick={setActiveTab} items={tabs} />\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useMemo,
      useState,
      Content,
      Paragraph,
      Space,
      Tabs,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const tabs = useMemo(() => [{
          name: '1',
          label: 'Tab №1'
        }, {
          name: '2',
          label: 'Tab №2',
          disabled: true
        }], []);
        const [activeTab, setActiveTab] = useState(tabs[0]);
        return <>
        <Tabs activeItem={activeTab} onItemClick={setActiveTab} items={tabs} mdxType="Tabs" />
      </>;
      }}
    </Playground>
    <h3 {...{
      "id": "tab-со-счетчиком"
    }}>{`Tab со счетчиком`}</h3>
    <p>{`Табу можно добавить счетчик, передав в объект таба свойство `}<inlineCode parentName="p">{`counter`}</inlineCode>{` с нужным значением`}</p>
    <Playground __position={5} __code={'() => {\n  const tabs = useMemo(\n    () => [\n      {\n        name: \'1\',\n        label: \'Tab №1\',\n      },\n      {\n        name: \'2\',\n        label: \'Tab №2\',\n        counter: 22,\n      },\n    ],\n    [],\n  )\n  const [activeTab, setActiveTab] = useState(tabs[0])\n  return (\n    <>\n      <Tabs activeItem={activeTab} onItemClick={setActiveTab} items={tabs} />\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useMemo,
      useState,
      Content,
      Paragraph,
      Space,
      Tabs,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const tabs = useMemo(() => [{
          name: '1',
          label: 'Tab №1'
        }, {
          name: '2',
          label: 'Tab №2',
          counter: 22
        }], []);
        const [activeTab, setActiveTab] = useState(tabs[0]);
        return <>
        <Tabs activeItem={activeTab} onItemClick={setActiveTab} items={tabs} mdxType="Tabs" />
      </>;
      }}
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={Tabs} mdxType="Props" />
    <p>{`Все остальные пропы пробрасываются на корневой элемент `}<inlineCode parentName="p">{`div`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      